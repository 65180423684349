#default-top-bar {
	& span.ant-input-affix-wrapper {
		border-radius: 50px;
	}
}

#mobile-top-bar {
	& .content *,
	.ant-tag * {
		font-size: 0.8rem !important;
	}

	& span.ant-input-affix-wrapper {
		border-radius: 50px;
		padding: 0.5rem;
	}

	& .ant-tag {
		font-size: 0.9rem !important;
		padding: 0.33rem 1rem !important;
		border-radius: 50px !important;
	}

	& li {
		padding: 0.02rem 1rem;
		border: 1px solid #d9d9d9;
		border-radius: 50px;
	}
}
