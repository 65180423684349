.tiptap,
.rte {
	> * + * {
		margin-top: 0.75em;
	}

	p {
		overflow-wrap: break-word;
	}
	ul,
	ol {
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
		padding: 1rem 0;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0d0d0d;
		border-radius: 0.5rem;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;

		code {
			background: none;
			color: inherit;
			font-size: 0.8rem;
			padding: 0;
		}
	}

	mark {
		background-color: #faf594;
	}

	img {
		height: auto;
		max-width: 100%;
	}

	hr {
		margin: 1rem 0;
	}

	blockquote {
		border-left: 2px solid rgba(#0d0d0d, 0.1);
		padding-left: 1rem;
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}

	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;

		li {
			align-items: center;
			display: flex;

			> label {
				flex: 0 0 auto;
				margin-right: 0.5rem;
				user-select: none;
			}

			> div {
				flex: 1 1 auto;
			}
		}
	}

	& span[data-type="emoji"] img {
		height: 1.2rem;
	}
}

.ProseMirror {
	> * + * {
		margin-top: 0.75em;
	}

	[data-type="emoji"] {
		img {
			width: 1em;
			height: 1em;
		}
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #000;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}
}

.editor {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 0.75rem;
	color: #0d0d0d;
	display: flex;
	flex-direction: column;
	overflow: "auto";

	&.mh-20 .ProseMirror {
		min-height: 20rem;
	}

	& .ProseMirror:focus {
		outline: none;
	}

	& .shadow {
		background: whitesmoke;
		-webkit-box-shadow: -2px -7px 37px 4px rgba(0, 0, 0, 0.34);
		-moz-box-shadow: -2px -7px 37px 4px rgba(0, 0, 0, 0.34);
		box-shadow: -2px -7px 37px 4px rgba(0, 0, 0, 0.34);
	}
}

.items {
	padding: 0.2rem;
	position: relative;
	border-radius: 0.5rem;
	background: #fff;
	color: rgba(0, 0, 0, 0.8);
	overflow: hidden;
	font-size: 0.9rem;
	box-shadow:
		0 0 0 1px rgba(0, 0, 0, 0.05),
		0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
	display: block;
	margin: 0;
	width: 100%;
	text-align: left;
	background: transparent;
	border-radius: 0.4rem;
	border: 1px solid transparent;
	padding: 0.2rem 0.4rem;

	&.is-selected {
		border-color: #000;
	}
}

span[data-type="emoji"] img,
.items img {
	width: 1em;
	height: 1em;
}
