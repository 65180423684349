@import "../base/_global.scss";

// .anticon {
// 	@include flex-center;
// }

.ant-message-custom-content {
	@include flex-center;
}

.radio-before-w {
	&-#{wInRem}::before {
		width: wInRem;
	}
}

.radio-before-w-0 {
	&.ant-radio-button-wrapper:not(:first-child)::before {
		width: 0;
	}
}

.anticon-close-circle,
.anticon-check-circle {
	/* Remove default */
	// -webkit-appearance: none;
	display: flex;
	justify-content: center;
	align-items: center;

	/* Now your own custom styles */
	margin: 0 0.2rem;
	font-size: 1rem;
	height: 100%;
	width: 1.2rem !important;
}

.ant-divider,
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
	border-top: 1px solid rgba(102, 102, 102, 0.25);
}

.ant-card-bordered,
.ant-input-affix-wrapper,
.ant-btn:not(.ant-btn-link) {
	border-color: #d9d9d9;
}

.ant-card-bordered {
	border-radius: 0.8rem;
}

.ant-space-item:has(> div.d-none),
.ant-space-item:has(> div.ant-form-item-hidden) {
	display: none;
}

.ant-message {
	z-index: 2000;
}

.ant-space-item:has(> div.custom-text-ellipsis) {
	@include ellipse;
}

.ant-space-item:has(> .ant-typography-ellipsis:first-child) {
	width: 100%;
}

.ant-drawer {
	z-index: 1045;
	background-color: #f3f4f6;
}

.ant-popover-buttons button,
.ant-upload-list-item-actions button {
	display: inline-block !important;
}

.ant-card-meta-detail > div:not(:last-child) {
	margin-bottom: 0;
}

.ant-card-body {
	padding: 0;
	// padding: 0.5rem 1.5rem;
	// padding-bottom: 1.2rem;
}

.ant-carousel .slick-dots li.slick-active {
	width: 2.5rem;
}

.ant-carousel .slick-dots li {
	width: 1.5rem;
}

.ant-carousel .slick-dots li.slick-active button {
	background: black !important;
}

.ant-carousel .slick-dots li button {
	background: black !important;
	padding: 0.3rem 0.25rem !important;
	border: 2px solid white !important;
	border-radius: 1rem !important;
}

ul.ant-rate li {
	margin-left: 0;
	margin-right: 0.4rem !important;
	// padding: 0 !important;
	padding-left: 0;
}

// .ant-card-meta h5.ant-typography {
// 	line-height: 1.2;
// }

// .ant-space-item {
// 	max-width: 95% !important;
// }

.ant-layout-header {
	line-height: 2rem;
}

.ant-modal-mask,
.ant-modal-wrap,
.ant-image-preview-mask,
.ant-image-preview-wrap {
	z-index: 2000;
}

.img-crop-modal button {
	display: inline-block !important;
}

.ant-image-preview-operations {
	background-color: rgba(0, 0, 0, 0.7);
}

.ant-rate-star-zero .ant-rate-star-second,
.ant-rate-star-half .ant-rate-star-second {
	color: lightgray !important;
}

.ant-input-number {
	padding: 0.3rem 0.3rem;
	border-radius: 0.5rem;
	width: 100%;
}

.ant-input-number-group-wrapper {
	width: 100%;
}

.css-dev-only-do-not-override-xu9wm8 .ant-image,
.css-xu9wm8 .ant-image {
	display: block;
}

.css-dev-only-do-not-override-xu9wm8 .ant-btn,
.css-xu9wm8 .ant-btn {
	padding: 0;
}

.ant-layout-header {
	height: auto;
}

.ant-popconfirm-buttons:has(> .flex-end) {
	display: flex;
	justify-content: flex-end;
}

.ant-popover {
	z-index: 10000 !important;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
	z-index: 50000 !important;
}
