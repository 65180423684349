@import "../base/global";

button:not(.ant-btn-link) {
	// @include flex-center;
	// max-width: 20rem;
}

.ant-modal-footer button {
	display: inline-block !important;
}

button span:not([role]) {
	@include ellipse;
}
