#profile-page,
#deal-page,
#job-page,
#housing-page,
#marketplace-page,
#review-page {
	& .info-description *:not(h1, h2, h3, h4, h5, h6) {
		font-size: 1rem !important;
	}
}
