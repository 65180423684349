@import "../base/global";

$typies: "primary", "business", "job", "housing", "marketplace", "customRed";

@each $type in $typies {
	.c {
		&-#{$type} {
			@include color($type);

			&-important {
				@include color($type, true);
			}
		}
	}

	.bg {
		&-#{$type} {
			@include background($type);

			&-important {
				@include background($type, true);
			}
		}
	}
}
